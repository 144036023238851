<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Logo Norvoz"
          class="shrink mr-2"
          contain
          src="./assets/logo_blanco.svg"
          transition="scale-transition"
          width="100"
        />
      </div>
      <v-chip
        class="ml-3 mt-5"
        color="green"
        outlined
        style="width: 70px; height: 15px"
      >
        <span style="color: green; font-size: 0.7em">v1.0.0-RC1</span>
      </v-chip>
      <v-spacer></v-spacer>

      <v-btn @click="cerrar()" target="_blank" text>
        <span class="mr-2">{{ this.extension }}</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-card v-show="mostrarFormulario" class="mx-auto my-12" width="450">
        <v-toolbar dark color="primary">
          <v-spacer />
          <v-toolbar-title>Acceso softphone</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="Identidad SIP "
            class="ml-4"
            v-model="extension"
          />
          <v-text-field
            label="Contraseña "
            type="password"
            class="ml-4"
            v-model="passExtension"
          />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn v-show="canRegistrar" color="primary" class="mr-4" @click="Registrar()">
            Registrar
          </v-btn>
        </v-card-actions>
      </v-card>

      <npm-softphone-janus
        :extensionCompleta="extension"
        :passwordExtension="passExtension"
        :modoDebug="'none'"
        ref="Softphone"
      />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {
  },

  data: () => ({
    extension: "",
    passExtension: "",
    mostrarFormulario: true,
  }),
  methods: {
    cerrar() {
      this.$refs.Softphone.desconectar();
      this.$refs.Softphone.extension = "";
      this.$refs.Softphone.passExtension = "";
      this.mostrarFormulario = true;
      this.extension = "";
    },
    Registrar() {
      this.$refs.Softphone.registrar();
    },
  },
  computed: {
    canRegistrar: function () {
      return (
        this.extension !== "" &&
        this.passExtension !== "" &&
        /^2001[0-9]{5}-[0-9]{1,4}@[a-z]{4,8}[1-3]{0,1}.norvoz.es/.test(
          this.extension
        ) == true
      );
    },
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.Softphone.mostrarSoftphone;
      },
      (val) => {
        if (val == true) {
          this.mostrarFormulario = false;
        }
      }
    );
  },
};
</script>
